<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
            :readonly="!!empresaClienteModelId || isEditting"
            :clearable="!isEditting"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="objEditar.nome"
            class="purple-input"
            :label="$t('CaixaForm.nome')"
            prepend-icon="mdi-cash-register"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-switch
            v-model="objEditar.ativo"
            :label="$t('padrao_ativo')"
            class="purple-input"
            prepend-icon="mdi-notebook-multiple"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="objEditar.saldo"
            readonly
            color="primary"
            prepend-icon="mdi-cash"
            :label="$t('CaixaForm.saldo')"
            type="number"
          />
        </v-col>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import Servico from '@/services/CaixaService'
  import { mapState } from 'vuex'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'

  export default {
    components: {
      EmpresaClienteAutoComplete
    },
    data: () => ({
      empresaClienteModelId: null,
      objPadrao: {
        nome: '',
        saldo: 0,
        ativo: true,
        idEmpresaCliente: null
      },
      objEditar: {
        nome: '',
        saldo: 0,
        ativo: true,
        idEmpresaCliente: null
      },
      empresaClienteModel: null,
      isEditting: true
    }),

    computed: {
      ...mapState(['idEmpresaCliente']),
      mensagemPerguntaRemocao () {
        return this.$i18n.t('padrao_deseja_remover_registro')
      },
      tituloPagina () {
        return this.$i18n.t('CaixaForm.tituloPagina')
      }
    },
    watch: {
      empresaClienteModel (val) {
        if (!val || val === null) {
          this.objEditar.idEmpresaCliente = null
          this.objEditar.EmpresaCliente = null
          this.empresaClienteModelId = null
        } else {
          this.objEditar.idEmpresaCliente = val.id
          this.objEditar.EmpresaCliente = val
          this.empresaClienteModelId = val.id
        }
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)
      }
    },
    methods: {
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (registro) {
        try {
          registro.idUsuarioAlteracao = JSON.parse(localStorage.arshome_auth).id
          Servico.update(registro)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: this.$t('padrao_sucesso'),
                  dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
                })
                this.closeDialog()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + error
          })
        }
      },
      closeDialog () {
        this.$router.push('/caixas')
      },
      remover () {
        const tipoEvento = this.objEditar
        try {
          Servico.delete(tipoEvento)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_removido_sucesso')
              })
              this.closeDialog()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + error
          })
        }
      },
      inserir (registro) {
        try {
          registro.idUsuarioAlteracao = JSON.parse(localStorage.arshome_auth).id
          Servico.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              this.closeDialog()
            }
          }).catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + error
            })
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + error
          })
        }
      },
      editar (id) {
        Servico.findById({ id })
          .then(result => {
            if (result.id) {
              this.objEditar = result
              this.isEditting = true
            } else if (result.data.id) {
              this.objEditar = result.data
              this.empresaClienteModel = this.objEditar.EmpresaCliente
              this.isEditting = true
            } else {
              this.isEditting = false
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
